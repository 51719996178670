<!--
 * @Description: 新闻管理
* @Autor: YuYang
 * @Date: 2024-03-05 16:00:34
 * @LastEditors: YuYang
 * @LastEditTime: 2024-03-05 16:00:34
-->
<template>
	<div class="m20 p20 bg-white">
		<div class="mb20">
			<el-button size='small' class='f-white bg-theme' @click="add">发布新闻</el-button>
		</div>

		<!-- 表格 -->
		<div class="mt30">
			<el-table :data="list" :header-cell-style="{background:'#f7f8fa',color:'#323233',fontWeight:500}"
				style="width: 100%">
				<el-table-column prop="name" label="标题" align='center' header-align='center'>
				</el-table-column>
				<el-table-column prop="name" label="封面" align='center' header-align='center'>
					<template slot-scope="scope">
						<img class="w50 h50" :src="scope.row.cover">
					</template>
				</el-table-column>
				<!--        <el-table-column
          prop="price"
          label="价格（元）"
          align='center'
          header-align='center'
        >
          <template slot-scope="scope">
            {{scope.row.price | moneyDec}}
          </template>
        </el-table-column> -->

				<!--        <el-table-column
          prop="originalPrice"
          label="原价（元）"
          align='center'
          header-align='center'
        >
          <template slot-scope="scope">
            {{scope.row.originalPrice | moneyDec}}
          </template>
        </el-table-column> -->

				<!-- <el-table-column
          prop="inventory"
          label="库存"
          align='center'
          header-align='center'
        >
        </el-table-column> -->

				<!-- <el-table-column
          prop="inventory"
          label="状态"
          align='center'
          header-align='center'
        >
        </el-table-column> 
		<el-table-column
		  prop="inventory"
		  label="排序"
		  align='center'
		  header-align='center'
		> -->
				</el-table-column>
				<el-table-column label="操作" align='center' header-align='center'>
					<template slot-scope="scope">
						<span class="mr5 f-theme pointer" @click="edit(scope.row)">编辑</span>
						<span class="ml5 mr5 f-theme pointer" @click="copy(scope.row)">复制</span>
						<span class="ml5 mr5 f-theme pointer">删除</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		getNewsList,
		copyNews
	} from "@/api/news";

	export default {
		name: "newsManager",

		created() {
			this.getList();
		},

		data() {
			return {
				list: [],
			};
		},

		computed: {
			...mapGetters(["project"]),
		},

		methods: {
			async getList() {
				let {
					status,
					list
				} = await getNewsList({
					projectId: this.project.id
				});
				if (status == "10000") {
					this.list = list;
				} else {
					if (status == "500") //jwt expired
					{
						message = "您的账号已经在别的电脑登录,当前登录已失效";
					}
					this.$notify({
						title: "失败",
						message: message,
						type: "warning",
					});
				}
			},

			add() {
				this.$router.push({
					name: "news-edit"
				});
			},

			edit(params) {
				this.$router.push({
					name: "news-edit",
					params: this.$cloneDeep(params),
				});
			},

			async copy(data) {
				let {
					status
				} = await copyNews(data);

				if (status == "10000") {
					this.getList();
					this.$notify({
						title: "成功",
						message: "新闻复制成功",
						type: "success",
					});
				}
			},
		},
	};
</script>